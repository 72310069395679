import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageNotFound from '../PageNotFound.vue';
import { getToken, getUserid, isLoggedIn } from '../services/auth';
import { encodeUrlPath } from '@/helpers/utils';
import procurementRoutes from '@/router/procRoutes';

const env = process.env;
const route: Array<RouteRecordRaw> = [
{
    path: "/login",
    name: "login",
    alias: "/login",
    component: () => {
      window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}login`;
      return null; // Adjust the import path accordingly
    },
    meta: { auth: false }
  },
  {
    path: '/',
    alias: '/procurement-dashboard',
    name: 'ProcurementDashboardPage',
    component: () =>
      import(
        /* webpackChunkName: "signin" */ '@/views/ProcurementDashboardPage.vue'
      ),
    meta: { auth: true }
  },

  {
    path: '/myglobalcomponentsarehere',
    name: 'ShowGlobal',
    component: () =>
      import(/* webpackChunkName: "global" */ '@/ui-kit/GlobalComponents.vue')
  },

  {
    path: '/legacy',
    name: 'legacy',
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    //  import(/* webpackChunkName: "legacy" */ "@/apps/HomePage.vue"),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    }
  },

  {
    path: '/user-profile',
    name: 'user-profile',
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`)
  },
  {
    path: '/request-loan',
    name: 'request-loan',
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal/loans`)
  },

  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

const isProcurementRoutesEnabled = !env.VUE_APP_PROCUREMENT_ROUTES_ENABLED;

export const routes = route.concat(
  isProcurementRoutesEnabled ? procurementRoutes : []
);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, _from, next) => {
  if (to.meta.auth && to.query['app-token']) {
    next();
  } else if (to.meta.auth && !isLoggedIn()) {
    const redirect = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}sso-login?redirect-to=${redirect}`;
  } else {
    next();
  }
});

export default router;

<template>
  <div
    class="
      absolute
      right-10
      top-0
      BG0
      w-[500px]
      px-6
      py-3
      shadow-md
      z-10
      rounded
      N800
      P200
      py-10
    "
    @mouseleave="$emit('mouse-leave-apps')"
  >
    <h3 class="H400 N900 mb-4">Apps</h3>
    <div
      :class="`grid grid-cols-4 w-fit justify-items-center gap-x-10 gap-y-5 px-3`"
    >
      <div v-for="(app, index) in displayedApps" :key="index" :text="app.text">
        <a
          href="javascript:void(0)"
          @click="routeUrl(app.link)"
          class="
            group
            w-[116px]
            h-[110px]
            rounded-lg
            group
            hover:bg-[#FFF0EA]
            py-2
            flex flex-col
            justify-center
            items-center
            N700
            P100
            text-center
            group-hover:text-[#DD5928]
          "
          @mouseenter="fill(app.key)"
          @mouseleave="unfill(app.key)"
        >
          <component
            :is="getIcon(app)"
            class="self-center"
            :filled="isFilled(app.key)"
          />
          <span style="white-space: pre-line">{{ app.text }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, ref } from 'vue';
import { useStore } from '@/store';
import config from '@/config/config';
import { AppType, FilledStates } from '@/types/componentTypes';
import PerformanceSvg from '../assets/menu-icons/PerformanceSvg.vue';
import PerformanceSvgFilled from '../assets/menu-icons/PerformanceSvgFilled.vue';
import ResourceSvg from '../assets/menu-icons/ResourceSvg.vue';
import ResourceSvgFilled from '../assets/menu-icons/ResourceSvgFilled.vue';
import EmployeeManagerSvg from '../assets/menu-icons/EmployeeManagerSvg.vue';
import EmployeeManagerSvgFilled from '../assets/menu-icons/EmployeeManagerSvgFilled.vue';
import ContractSvg from '../assets/menu-icons/ContractSvg.vue';
import ContractsSvgFilled from '../assets/menu-icons/ContractsSvgFilled.vue';
import ClockITSvg from '../assets/menu-icons/ClockITSvg.vue';
import ClockITSvgFilled from '../assets/menu-icons/ClockITSvgFilled.vue';
import PayrollSvg from '../assets/menu-icons/PayrollSvg.vue';
import PayrollSvgFilled from '../assets/menu-icons/PayrollSvgFilled.vue';
import InvoiceSvg from '../assets/menu-icons/InvoiceSvg.vue';
import InvoiceSvgFilled from '../assets/menu-icons/InvoiceSvgFilled.vue';
import ProcurementSvg from '../assets/menu-icons/ProcurementSvg.vue';
import ProcurementSvgFilled from '../assets/menu-icons/ProcurementSvgFilled.vue';
import IncidenceManagerSvg from '../assets/menu-icons/IncidenceManagerSvg.vue';
import IncidenceManagerSvgFilled from '../assets/menu-icons/IncidenceManagerSvgFilled.vue';
import MeetingRoomBookingSvg from '../assets/menu-icons/MeetingRoomBookingSvg.vue';
import MeetingRoomBookingSvgFilled from '../assets/menu-icons/MeetingRoomBookingSvgFilled.vue';
import { getToken } from '@/services/auth';
import { loggedInUsersAccessLevelStore } from '@/store/loggedin_users_access_level';

const user = useStore()?.user;
const procStore = loggedInUsersAccessLevelStore();

const loginHint: string = procStore.login_hint;

const filledStates: Ref<FilledStates> = ref({});
const permitedApps = user?.apps;

const fill = (appKey: string): void => {
  filledStates.value[appKey] = true;
};

const unfill = (appKey: string): void => {
  filledStates.value[appKey] = false;
};

function getIcon(app: AppType) {
  return filledStates.value[app.key] ? app.filledIcon : app.icon;
}

const isFilled = (appKey: string): boolean => {
  return filledStates.value[appKey];
};

const apps: AppType[] = [
  {
    text: 'Performance Manager',
    link: config.performanceLink,
    icon: PerformanceSvg,
    filledIcon: PerformanceSvgFilled,
    key: 'performance management'
  },
  {
    text: 'Resource Manager',
    link: config.resourceLink,
    icon: ResourceSvg,
    filledIcon: ResourceSvgFilled,
    key: 'resource management'
  },
  {
    text: 'Employee Manager',
    link: config.employeeManagerLink,
    icon: EmployeeManagerSvg,
    filledIcon: EmployeeManagerSvgFilled,
    key: 'employee management'
  },
  {
    text: 'Contract Manager',
    link: config.contractManagerLink,
    icon: ContractSvg,
    filledIcon: ContractsSvgFilled,
    key: 'contract management'
  },
  {
    text: 'Clock-IT',
    link: config.clockItLink,
    icon: ClockITSvg,
    filledIcon: ClockITSvgFilled,
    key: 'time tracker'
  },
  {
    text: 'Payroll',
    link: config.payrollLink,
    icon: PayrollSvg,
    filledIcon: PayrollSvgFilled,
    key: 'payroll'
  },
  {
    text: 'Invoice Manager',
    link: config.invoiceManagerLink,
    icon: InvoiceSvg,
    filledIcon: InvoiceSvgFilled,
    key: 'invoice manager'
  },
  {
    text: 'Procurement Manager',
    link: config.procurementManagerLink,
    icon: ProcurementSvg,
    filledIcon: ProcurementSvgFilled,
    key: 'procurement manager'
  },
  {
    text: 'Loan Manager',
    link: config.loansManagerLink,
    icon: PayrollSvg,
    filledIcon: PayrollSvgFilled,
    key: 'loans'
  },
  {
    text: 'Incidence Manager',
    link: config.incidenceManagerLink,
    icon: IncidenceManagerSvg,
    filledIcon: IncidenceManagerSvgFilled,
    key: 'incidence manager'
  },
  {
    text: 'Meeting Room Booking',
    link: config.meetingRoomBookingLink,
    icon: MeetingRoomBookingSvg,
    filledIcon: MeetingRoomBookingSvgFilled,
    key: 'meeting booking'
  }
];

const routeUrl = (link = '') => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${link}`;
  }
};
const displayedApps = computed(() => {
  return apps
    .filter((app) => permitedApps?.includes(app.key))
    .sort((a, b) => a.text.localeCompare(b.text));
});
</script>

import { CapApproverInt } from '../interfaces/types';
export const userNameInitials = (name: string) => {
  return name
    .toString()
    .split(' ')
    .map((str: string) => str[0])
    .join('');
};

export function getCurrentDate(dateString: number): string {
  const date = new Date(dateString);

  const day = String(date.getDate());
  const month = getMonthName(date.getMonth());
  const year = date.getFullYear();
  const daySuffix = getDaySuffix(date.getDate());

  return `${day}${daySuffix} ${month} ${year}`;
}

function getMonthName(month: number): string {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  return monthNames[month];
}

export function getDaySuffix(day: number): string {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function formatAmountOverflow(value: number): string {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formatter.format(value);
}

export function formatAmountWithGHC(value: string) {
  const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));

  const formattedValue = new Intl.NumberFormat('en-GH', {
    style: 'currency',
    currency: 'GHS'
  }).format(numericValue);

  return formattedValue;
}

export function formatAmountWithGHCGhs(value: string) {
  const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));

  return `GHS${numericValue}`;
}

export function fileNameShortner(file: string) {
  return file.slice(0, 10) + '...' + '';
}

export function ImageNameShortner(file: string) {
  return file.slice(0, 10) + '...';
}

export const variable = {
  input: {
    skip: 0,
    take: 9
  }
};

export const findData = (id: string, data: CapApproverInt[]) => {
  return data.find((item) => item.id === Number(id));
};

export const validateEmail = (email: string): RegExpExecArray | null => {
  if (email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.exec(email.toLowerCase());
  }
  return null;
};


export function downloadFile(url:string) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const createRoute = (path: string, name: string, chunkName: string, componentPath: string) => ({
  path,
  name,
  meta: { auth: true },
  component: () => import(/* webpackChunkName: chunkName */ `../views/${componentPath}.vue`)
});

export const getUserNameInitials = (name: string): string => {
    let words = name?.trim().split(" ");
    words = words?.length > 2 ? words.slice(0, 2) : words;
    return words?.map((word) => word[0]).join("") || "";
  };
<template>
  <RouterLink :to="`${item?.route}`" @click="checkoukRout">
    <div
      class="
        flex
        items-center
        gap-4
        rounded-lg
        mr-2
        px-4
        cursor-pointer
        my-4
        py-3
        hover:bg-[#fff0ea]
        w-full
      "
      :class="
        $route.path === item?.route && store.showAll ? 'bg-[#fff0ea]' : ''
      "
    >
      <div
        :title="item?.label"
        v-if="!store.showAll"
        class="flex justify-center items-center -mr-2"
      >
        <slot></slot>
      </div>

      <div v-else>
        <slot></slot>
      </div>
      <span
        class="
          text-base
          sm:hidden
          md:hidden
          lg:block
          xl:block
          whitespace-nowrap
        "
        :class="$route.path === item?.route ? 'O400' : ''"
        v-if="store.showAll"
        >{{ item?.label }}</span
      >
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { useStore } from '@/store/index';
import { PropType } from 'vue';
import { settingsnavigation } from '@/store/proc_store';

const settnavigate = settingsnavigation();

//using to remove capapprover setup information dialogue
const checkoukRout = () => {
  settnavigate.setSettingsNav('');
};

const store = useStore();
let props = defineProps({
  item: {
    type: Object as PropType<{ label: string; route: string }>
  }
});
</script>
